<template>
  <div>
    <!-- {{ permissionRoles }} -->
    <!-- Header -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">
        Benutzer hinzufügen
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>

    <!-- BODY -->
    <!-- {{ editUserEntry }} -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="userForm"
        class="p-2"
        @submit="submitUser"
      >
        <div class="pb-1 pt-1">
          <h5>Benutzerinformationen</h5>
        </div>
        
        <!-- <b-form-group
          label="Benutzerrolle"
          label-for="v-role"
        >
          <validation-provider
            #default="{ errors }"
            name="Rolle"
            rules="required"
          >
            <v-select
              v-if="isEditMode"
              v-model="editUserEntry.role"
              placeholder="Rolle"
              label="name"
              :options="$store.getters.permissionRoles.roles"
              :clearable="false"
            />
            <v-select
              v-else
              v-model="user.role"
              placeholder="Rolle"
              label="name"
              :options="$store.getters.permissionRoles.roles"
              :clearable="false"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
        <!-- Username -->
        <b-form-group
          label="Benutzername"
          label-for="v-username"
        >
          <validation-provider
            #default="{ errors }"
            name="Benutzername"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editUserEntry.username"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="user.username"
              :state="errors.length > 0 ? false : null"
              placeholder="Batman"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Vorname -->
        <b-form-group
          label="Vorname"
          label-for="v-firstname"
        >
          <validation-provider
            #default="{ errors }"
            name="Vorname"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editUserEntry.firstName"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="user.firstName"
              :state="errors.length > 0 ? false : null"
              placeholder="Bruce"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Nachname"
          label-for="v-lastname"
        >
          <validation-provider
            #default="{ errors }"
            name="Nachname"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editUserEntry.lastName"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="user.lastName"
              :state="errors.length > 0 ? false : null"
              placeholder="Wayne"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="v-email"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editUserEntry.email"
              :state="errors.length > 0 ? false : null"
              type="email"
            />
            <b-form-input
              v-else
              v-model="user.email"
              :state="errors.length > 0 ? false : null"
              type="email"
              placeholder="bruce@wayne.de"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label="Passwort"
          label-for="v-password"
        >
          <validation-provider
            #default="{ errors }"
            name="Password"
            vid="Password"
            rules="required|password"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editUserEntry.password"
              :state="errors.length > 0 ? false : null"
              type="password"
            />
            <b-form-input
              v-else
              v-model="user.password"
              :state="errors.length > 0 ? false : null"
              type="password"
              placeholder="············"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Passwort bestätigen"
            rules="required|confirmed:Password"
          >
            <b-form-input
              v-model="user.passwordCon"
              :state="errors.length > 0 ? false:null"
              type="password"
              placeholder="Passwort bestätigen"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            @click.prevent="validationForm"
          >
            <div v-if="isEditMode">
              Aktualisieren
            </div>
            <div v-else>
              Hinzufügen
            </div>
          </b-button>

          <b-button
            type="button"
            variant="outline-secondary"
            @click="closeSidebar"
          >
            Abbrechen
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BFormInputGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInputGroup,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    ToastificationContent,
    
  },
  mixins: [togglePasswordVisibility],
  props: ['isEditMode', 'editUserEntry'],
  data() {
    return {
      user: {
        username: '',
        email: '',
        provider: '',
        password: '',
        passwordCon: '',
        // resetPasswordToken: '',
        // confirmationToken: '',
        confirmed: true,
        blocked: false,
        role: '',
        firstName: '',
        lastName: '',
        // created_by: '',
        // updated_by: '',
      },
      required,
    }
  },
  computed: {
    permissionRoles() {
      return this.$store.getters.permissionRoles
    },
  },
  // mounted() {
  //   this.$store.dispatch('getUserEntries')
  // },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitUser()
          // this.$refs.userForm.submit()
        }
      })
    },
    closeSidebar() {
      if (this.isEditMode) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
      } else {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
    },

    submitUser() {
      // EditMode
      if (this.isEditMode) {
        this.$store
          .dispatch('updateUser', this.editUserEntry).then(() => {
          console.log('updateUser success')
          this.$store.dispatch('getUserEntries')
          this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
          this.toastNotification('Benutzer aktualisiert', 'CheckIcon', 'success')
        }).catch(err => {
            console.error(err)
            this.toastNotification('Benutzer konnte nicht aktualisiert werden', 'AlertTriangleIcon', 'danger')
          })
      } else {
        // AddMode
        this.$store.dispatch('addUser', this.user).then(() => {
          console.log('addUser success')
          this.$store.dispatch('getUserEntries')
          this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
          this.toastNotification('Benutzer hinzugefügt', 'CheckIcon', 'success')
        }).catch(err => {
          console.error(err)
          this.toastNotification('Benutzer konnte nicht hinzugefügt werden', 'AlertTriangleIcon', 'danger')
        })
      }
    },

    toastNotification(title, icon, variant) {
       this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>
<style lang=""></style>
