<template lang="">
  <div v-if="loggedIn">
    {{ errorContent }}
    <b-row class="match-height">
      <b-col xl="3" md="4" sm="6">
        <statistic-card-vertical
          color="warning"
          icon="UsersIcon"
          :statistic="userEntries.length"
          statistic-title="Benutzer"
        />
      </b-col>
    </b-row>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Zeige</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Suche..."
                type="search"
              />
              <b-button
                v-if="showEditButton"
                variant="primary"
                @click="createNewObject()"
              >
                <span class="text-nowrap">Benutzer hinzufügen</span>
              </b-button>
              <b-sidebar
                id="sidebar-right"
                bg-variant="white"
                right
                backdrop
                shadow
                no-header
              >
                <sidebar-content />
              </b-sidebar>
              <b-sidebar
                id="sidebar-right-edit"
                bg-variant="white"
                right
                backdrop
                shadow
                no-header
              >
                <sidebar-content
                  :is-edit-mode="isEditMode"
                  :edit-user-entry="editUserData"
                />
              </b-sidebar>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-card no-body>
          <!-- Table -->
          <b-table
            ref="refListTable"
            striped
            hover
            :items="userEntries"
            :fields="fields"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            responsive
            show-empty
            empty-text="Keine Einträge gefunden"
            @row-clicked="onRowClicked"
          >
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :to="{ name: 'customers-view', params: { id: data.item.id } }"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Öffnen</span>
                </b-dropdown-item>

                <b-dropdown-item @click="editObject(data)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Bearbeiten</span>
                </b-dropdown-item>

                <b-dropdown-item @click="deleteWarning(data)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Löschen</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
                  {{ dataMeta.of }} Einträge</span
                >
              </b-col>

              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="getTotalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
    </b-card>
  </div>
  <div v-else>Forbidden</div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BPagination,
  BBadge,
  BLink,
  BSidebar,
  VBToggle,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
// import UserService from '@/service/customer.service'
import SidebarContent from '@/views/pages/user/list/UserListAddNew.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import tableHelper from '../../Table+Helper'

export default {
  setup() {
    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      perPageOptions,
      filter,
      perPage,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    }
  },
  components: {
    BTable,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    BBadge,
    BLink,
    vSelect,
    StatisticCardVertical,
    BSidebar,
    VBToggle,
    SidebarContent,
    BDropdown,
    BDropdownItem,
    ToastificationContent,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      // customerEntries: [],
      errorContent: '',
      editUserData: [],
      isEditMode: false,
      fields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          key: 'username',
          label: 'Benutzername',
          sortable: true,
        },
        {
          key: 'firstName',
          label: 'Vorname',
          sortable: true,
        },
        {
          key: 'lastName',
          label: 'Nachname',
          sortable: true,
        },
        {
          key: 'email',
          label: 'E-Mail',
          sortable: true,
        },
        {
          key: 'email',
          label: 'E-Mail',
          sortable: true,
        },
        {
          key: 'role.name',
          label: 'Rolle',
          sortable: true,
        },
        {
          key: 'actions',
          label: 'Aktionen',
        },
      ],
      customers: [],
      loading: false,
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.user
    },
    userEntries() {
      return this.$store.getters.userEntries
    },
    meEntry() {
      return this.$store.getters.meEntry
    },
    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    getTotalRows() {
      return this.userEntries.length
    },
    showEditButton() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.name.includes('Admin')
      }
      return false
    },
  },
  mounted() {
    this.$store.dispatch('getUserEntries')
  },
  updated() {
    // Set the initial number of items
    this.totalRows = this.getTotalRows
  },
  methods: {
    onRowClicked(item) {
      this.$router.push(`/users-view/${item.id}`)
    },
    deleteWarning(data) {
      this.$swal({
        title: 'Bist du sicher?',
        // text: 'Das Lager wird unwiederruflich gelöscht.',
        text: `Den Benutzer "${data.item.firstName} ${data.item.lastName}" wirklich löschen?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ja, löschen!',
        cancelButtonText: 'Abbrechen',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store
            .dispatch('deleteUser', data.item.id)
            .then(() => {
              this.$store.dispatch('getUserEntries')
              this.toastNotification(
                'Benutzer erfolgreich gelöscht.',
                'CheckIcon',
                'success'
              )
            })
            .catch(() => {
              this.toastNotification(
                'Benutzer konnte nicht gelöscht werden.',
                'AlertTriangleIcon',
                'danger'
              )
            })
        }
      })
    },

    toastNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    createNewObject() {
      this.isEditMode = false
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    editObject(data) {
      this.editUserData = data.item
      this.isEditMode = true
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
