import { computed, ref, watch } from '@vue/composition-api'

export default function tableHelper() {
  const refListTable = ref(null)

  const perPageOptions = [10, 25, 50, 100]
  const perPage = ref(10)
  const currentPage = ref(1)
  const totalRows = ref(0)
  const filter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value
      ? refListTable.value.paginatedItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRows.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
    console.log('refetschData', refListTable.value)
  }

  watch([currentPage, perPage, filter], () => {
    refetchData()
  })

  return {
    perPageOptions,
    perPage,
    currentPage,
    totalRows,
    filter,
    dataMeta,
    refListTable,
    refetchData,
  }
}
